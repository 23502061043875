import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/b-fullam/Automating-VirusTotal-APIv3-for-IPs-and-URLs",
            "title": "Automating VirusTotal API v3 for IP address and URL analysis with HTML Reporting"
          }}>{`Automating VirusTotal API v3 for IP address or URL analysis that generates custom HTML reports with Python`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/b-fullam/IOC-Generator-v2",
            "title": "IOC-Generator-v2"
          }}>{`IOC Generator v2.0 | Quickly generate common Indicators of Compromise (IOCs) from files with Python.`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/b-fullam/DNS-Lookups-v2",
            "title": "DNS Lookups v2"
          }}>{`Automating DNS and Reverse DNS Lookups with Python`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/b-fullam/Advanced-Checksum-Checker",
            "title": "Advanced Checksum Checker"
          }}>{`Advanced Checksum Checker – Python 3`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      